import React, { Fragment } from 'react'
import _get from 'lodash/get'
import { Link, graphql } from 'gatsby'
import { ChevronsRight, ChevronsLeft } from 'react-feather'

import Content from '../components/Content'
import Layout from '../components/Layout'
import './SinglePost.css'
import '../components/Devices.css'

export const SingleProjectTemplate = ({
  title,
  date,
  author,
  body,
  nextProjectURL,
  prevProjectURL,
  nextProjectTitle,
  prevProjectTitle,
  categories = [],
  tags = []
}) => (
  <main>
    <article
      className="SinglePost section"
      itemScope
      itemType="http://schema.org/BlogPosting"
    >
      <div className="container">
        <div className="SinglePost--Content relative">
          <div className="SinglePost--Meta">
            {categories && (
              <Fragment>
                {categories.map((cat, index) => (
                  <span
                    key={cat.category + '-' + index}
                    className="SinglePost--Meta--Category"
                  >
                    {cat.category}
                    {/* Add a comma on all but last category */}
                    {index !== categories.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </Fragment>
            )}
          </div>

          {title && (
            <h1 className="SinglePost--Title" itemProp="title">
              {title}
            </h1>
          )}

          <div className="SinglePost--InnerContent">
            {/* Check if date or author has been declared in MD file
            If so, render the meta */}

            <div className="SinglePost--Meta">
              <div className="SinglePost--Meta--Author">
                {/* If there is author, display it */}
                {author && `By ${author}`}
                {!author && `By Daniel Kim`}
              </div>

              {tags && (
                <div className="SinglePost--Meta--TagGroup">
                  {tags.map((tag, index) => (
                    <Fragment key={tag + '-' + index}>
                      <Link
                        className="SinglePost--Meta--Tag"
                        key={tag + '-' + index}
                        to={`/tag/${tag
                          .split(' ')
                          .join('-')
                          .split('/')
                          .join('-')
                          .toLowerCase()}`}
                      >
                        {tag}
                      </Link>
                      <span>
                        {/* Add a comma on all but last tag */}
                        {index !== tags.length - 1 ? ', ' : ''}
                      </span>
                    </Fragment>
                  ))}
                </div>
              )}
              {/* If there is date, display it */}
              {date && (
                <Fragment>
                  <div className="SinglePost--Meta--Date screen-reader-text">
                    <span>Published on </span>
                    <time
                      className="SinglePost--Meta--Date"
                      itemProp="dateCreated pubdate datePublished"
                      date={date}
                    >
                      {date}
                    </time>
                  </div>
                </Fragment>
              )}
            </div>

            <Content source={body} />
          </div>

          <div className="SinglePost--Pagination">
            {prevProjectURL && (
              <div className="SinglePost--Pagination--Nav prev">
                <Link
                  className="SinglePost--Pagination--Link"
                  to={prevProjectURL}
                >
                  <div className="SinglePost--Pagination--Nav--Inner">
                    <div className="subtle-text">
                      <ChevronsLeft size={60} />
                    </div>
                    {prevProjectTitle}
                  </div>
                </Link>
              </div>
            )}
            {nextProjectURL && (
              <div className="SinglePost--Pagination--Nav next">
                <Link
                  className="SinglePost--Pagination--Link"
                  to={nextProjectURL}
                >
                  <div className="SinglePost--Pagination--Nav--Inner">
                    <div className="subtle-text">
                      <ChevronsRight size={60} />
                    </div>
                    {nextProjectTitle}
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  </main>
)

// Export Default SingleProject for front-end
const SingleProject = ({ data: { project, allProjects } }) => {
  const thisEdge = allProjects.edges.find((edge) => edge.node.id === project.id)
  return (
    <Layout
      meta={project.frontmatter.meta || false}
      title={project.frontmatter.title || false}
    >
      <SingleProjectTemplate
        {...project}
        {...project.frontmatter}
        body={project.html}
        nextProjectTitle={_get(thisEdge, 'next.frontmatter.title')}
        nextProjectURL={_get(thisEdge, 'next.fields.slug')}
        prevProjectURL={_get(thisEdge, 'previous.fields.slug')}
        prevProjectTitle={_get(thisEdge, 'previous.frontmatter.title')}
      />
    </Layout>
  )
}

export default SingleProject

export const pageQuery = graphql`
  ## Query for SingleProject data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleProject($id: String!) {
    project: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        subtitle
        author
        date(formatString: "MMMM Do, YYYY")
        categories {
          category
        }
        tags
      }
    }

    allProjects: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "projects" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
